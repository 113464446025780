<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <b-card>
          <b-card-text>
            <div>
              <b-form @reset="resetForm">
                <b-form-group
                  :key="field.label"
                  :label="field.label"
                  v-for="field in fields"
                >
                  <b-form-input
                    v-if="field.formType !== 'boolean'"
                    v-model="form[field.key]"
                    :type="field.formType || 'text'"
                    required
                  ></b-form-input>

                  <toggle-button
                    v-if="field.formType === 'boolean'"
                    v-model="form[field.key]"
                    :value="true"
                    :labels="{ checked: 'ON', unchecked: 'OFF' }"
                    :color="{
                      checked: '#1BC5BD',
                      unchecked: '#F64E60',
                      disabled: '#CCCCCC',
                    }"
                  />
                </b-form-group>

                <b-button variant="primary" class="mr-2" @click="submit"
                  >Guardar
                </b-button>
                <b-button type="reset" variant="danger" v-if="isEdit === false"
                  >Limpiar
                </b-button>
              </b-form>
            </div>
          </b-card-text>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "../../../core/services/store/breadcrumbs.module";
import ApiService from "../../../core/services/api.service";

export default {
  name: "ClientCreate",
  data() {
    return {
      model: "Cliente",
      action: "Crear",
      form: { on_trial: false },
      isEdit: false,
      fields: [
        { key: "name", label: "Nombre de la empresa" },
        { key: "schema_name", label: "Esquema (código único)" },
        { key: "paid_until", label: "Pagado hasta", formType: "date" },
        { key: "ruc", label: "RUC" },
        { key: "frontend_url", label: "URL frontend" },
        { key: "on_trial", label: "¿Es demo?", formType: "boolean" },
      ],
    };
  },
  mounted() {
    this.getItem();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Cliente", route: `/core/client` },
      { title: `${this.action} Cliente` },
    ]);
  },
  methods: {
    getItem() {
      if (this.$route.params.id === undefined) {
        return false;
      }
      this.action = "Editar";
      this.isEdit = true;
      ApiService.get(`cliente/${this.$route.params.id}`).then((res) => {
        this.form = res.data;
      });
    },
    resetForm() {
      this.form = { on_trial: false };
    },
    submit() {
      let method = this.isEdit === true ? ApiService.put : ApiService.post;
      let url =
        this.isEdit === true
          ? `https://veleidosa.sakya.maytok.com/core/client/${this.form.id}/`
          : `https://veleidosa.sakya.maytok.com/core/client/`;

      method(url, this.form)
        .then(() => {
          this.$successToast();
          if (this.isEdit === false) {
            this.form = {};
          }
        })
        .catch((err) => {
          console.log(err);
          this.$errorToast(err);
        });
    },
  },
};
</script>

<style scoped></style>
